import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingWorks: Symbol[] = [];
  private loadingStatus$ = new BehaviorSubject<boolean>(false);

  activateLoading(): Symbol {
    const symbol = Symbol();
    this.loadingWorks.push(symbol);
    this.loadingStatus$.next(this.isLoading());

    return symbol;
  }

  removeLoading(symbol: Symbol) {
    const idx = this.loadingWorks.findIndex(arrSymbol => arrSymbol === symbol);

    if (idx !== -1) {
      this.loadingWorks.splice(idx, 1);
      this.loadingStatus$.next(this.isLoading());
    }
  }

  isLoading(): boolean {
    return !!this.loadingWorks.length;
  }

  getLoading(): Observable<boolean> {
    return this.loadingStatus$.asObservable();
  }
}
